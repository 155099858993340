var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('VDataTable',{attrs:{"headers":[
        { text: 'ID', value: 'id', sortable: false },
        { text: 'ИНН', value: 'inn', sortable: false },
        { text: 'Организация', value: 'name', sortable: false },
        { text: 'Статус', value: 'status.name', sortable: false },
        { text: 'Средняя профильная оценка ПР', value: 'mark.average', sortable: false},
        { text: 'Общая квалификационная оценка', value: 'mark.general', sortable: false},
        { text: 'Интегральная комплексная оценка ПР', value: 'mark.integral', sortable: false},
        { text: 'Комплексная оценка ИИ', value: 'mark.complexEngineer', sortable: false},
        { text: 'Экспертная оценка', value: 'mark.expert', sortable: false} ].concat( Object.keys(_vm.markSections).map(function (code) { return ({ text: _vm.markSections[code], value: ("sections." + code + ".value"), sortable: false}); }) ),"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
        itemsPerPageText: '',
      },"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.count,"mobile-breakpoint":0,"item-class":_vm.rowClassControl},on:{"update:options":function($event){_vm.options=$event},"update:page":function (page) { return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, {page: page}) }); },"update:items-per-page":function (size) { return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, {size: size}) }); }},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [(_vm.isReviewer || _vm.isSpectator)?_c('tr',[_vm._l((['id', 'inn', 'companyName']),function(key){return _c('td',{key:key},[_c('VTextField',{attrs:{"dense":"","clearable":"","hide-details":"","value":_vm.$route.query[key]},on:{"input":function (value) {
            var _obj;

            return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, ( _obj = {}, _obj[key] = value, _obj )) });
      }}})],1)}),_c('td',[_c('VSelect',{attrs:{"dense":"","clearable":"","hide-details":"","items":_vm.statuses,"value":_vm.$route.query['status']},on:{"input":function (value) {
            var _obj;

            return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, ( _obj = {}, _obj['status'] = value, _obj )) });
      }}})],1),_c('td',{attrs:{"colspan":3 + Object.keys(_vm.markSections).length}})],2):_vm._e()]},proxy:true},{key:"item.control",fn:function(ref){
      var ref_item = ref.item;
      var id = ref_item.id;
      var comments = ref_item.comments;
return [_vm._t("control",null,null,{ id: id, comments: comments })]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }